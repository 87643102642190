<template>
<div>
    <popup-modal ref="modal" :show="showModal" class="text-2xl" v-on:close="validate" :black="black" :action="action" :bottom="bottom" :noclose="showError" :gold="true">
        <h3 class="text-gray-200">{{ title }}</h3>
        <div v-if="!showError" class="">
            <div class="text-2xl text-center pt-6 text-gray-200" >{{ message }}</div>
            <div class="flex items-center justify-center pt-4 pb-8">
                <input type="date" max="today" v-model="value" class="px-4 py-2 text-4xl"/>
            </div>
        </div>
        <div v-else>
            <div class="text-2xl text-center pt-6 text-gray-200">
                {{ denied }}
            </div>
        </div>
    </popup-modal>
</div>
</template>

<script>
import PopupModal from './PopupModal'
import EventBus from '../EventBus'

import dayjs from 'dayjs'

export default {
    props: {
        title: String,
        message: String,
        action: String,
        denied: String,
        black: Boolean,
        auto: Boolean,
        bottom: Boolean,
    },
    components: {
        PopupModal,
    },
    data() {
        return {
            showModal: this.auto,
            today: dayjs().format('YYYY-MM-DD'),
            value: undefined,
            showError: false,
        }
    },
    methods: {
        show() {
            this.showModal = true
            EventBus.$emit('craft:noscroll', this.$refs.modal)
        },
        hide() {
            this.showModal = false
            EventBus.$emit('craft:scroll', this.$refs.modal)
        },
        validate() {
            if (this.value) {
                if (dayjs(this.value).isBefore(dayjs().subtract(18, 'years'))) {
                    this.hide();
                    EventBus.$emit('ageVerified');
                }
                else {
                    this.showError = true;
                }
            }
            console.log("You tried to enter")
        }
    }
}
</script>
