import Vue from 'vue';
import "./css/main.scss";
import Components from "./components";
import VueAwesomeSwiper from 'vue-awesome-swiper'
import axios from 'axios';
import EventBus from './EventBus'
import $ from 'jquery';
require('jquery-serializejson');
import cart from './modules/cart';
cart();

// import style
import 'swiper/css/swiper.css'

Vue.use(VueAwesomeSwiper)
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);


if ($("#app").length) {
  new Vue({
    el: "#app",
    delimiters: ["[[", "]]"],
    components: {
     LocalSwiper: VueAwesomeSwiper.swiper,
     LocalSlide: VueAwesomeSwiper.swiperSlide,
   },
   data() {
    return {
      csrfName: window.csrfTokenName,
      csrfToken: window.csrfTokenValue,
      validateAge: !this.retrieveAgeVerified(),
      couponError: '',
      cartOpen: false,
      cartSummary: false,
      cart: {
        numberOfItems: 0,
        totalPrice: '',
        items: {}
      },
      cartLoading: false,
      cartAddressForm: {
          billingAddressSameAsShipping: true
      },
      swiperOptionTop: {
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionTop: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 0,
        dynamicBullets:true,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true,
        breakpoints:{
          600: {
             spaceBetween: 10,
             dynamicBullets:false,
     },
        }
      },
      swiperOptionCollection: {
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
      },
      swiperOptionFeatured1: {
        slidesPerView: 3,
        spaceBetween: 30,
        navigation: {
          nextEl: '.next1',
          prevEl: '.prev1'
        },
        breakpoints:{
          1000: {
              slidesPerView: 3
            },
          550: {
              slidesPerView: 2,
              dynamicBullets:true,
            },
            400: {
                slidesPerView: 1
              }
          }
      },
      swiperOptionFeatured2: {
        slidesPerView: 3,
        spaceBetween: 30,
        navigation: {
          nextEl: '.next2',
          prevEl: '.prev2'
        },
        breakpoints:{
          1000: {
              slidesPerView: 3
            },
          550: {
              slidesPerView: 2,
              dynamicBullets:true,
            },
            400: {
                slidesPerView: 1
              }
          }
      },
      swiperOptionFeatured3: {
        slidesPerView: 3,
        spaceBetween: 30,
        navigation: {
          nextEl: '.next3',
          prevEl: '.prev3'
        },
        breakpoints:{
          1000: {
              slidesPerView: 3
            },
          550: {
              slidesPerView: 2,
              dynamicBullets:true,
            },
            400: {
                slidesPerView: 1
              }
          }
      },
      swiperCheckoutMeter:{
        slidesPerView: "auto",
        spaceBetween: 1,
        grabCursor: true,
        centeredSlides: true
      }
    }
  },
  created() {
    this.cartAddress();
  },
  mounted() {
    if (typeof this.$refs.swiperTop !== 'undefined') {
      this.$nextTick(() => {
        const swiperTop = this.$refs.swiperTop.$swiper
        const swiperThumbs = this.$refs.swiperThumbs.$swiper
        swiperTop.controller.control = swiperThumbs
        swiperThumbs.controller.control = swiperTop
      })
    }
    $('.field__clear').click(function(){
      $this = $(this);
      $this.closest('.field').find('input, textarea').val('');
    });

    EventBus.$on('ageVerified', this.storeAgeVerified);
    this.cartUpdate();
  },
  methods: {
    addToCart(purchasableId, qty) {
      /**
       * USAGE:
       *
       *   <a href="#" @click.prevent="addToCart(7, 1)">Add To Cart</a>
       */
      const _this = this;

      axios.post('/actions/commerce/cart/update-cart', {
        'purchasableId': purchasableId,
        'qty': qty,
        [this.csrfName]: this.csrfToken
      })
      .then(function (response) {
        _this.cartOpen = true;
      })
      .catch(function (error) {
        console.log(error);
      });

      EventBus.$emit('addToCart');
    },
    retrieveAgeVerified() {
        try {
        let stateData = localStorage.getItem('age-validated');
            if (stateData === null) {
                return false
            }
            return JSON.parse(stateData).valid
        } catch (error) {
            return false
        }
    },
    storeAgeVerified() {
        localStorage.setItem('age-validated', JSON.stringify({"valid": "true"}));
    },
    addToCartForm(formId) {
      /**
       * USAGE:
       *
       *   <form id="form-id" v-on:submit.prevent="addToCartForm('form-id')">
       *     <input type="hidden" name="qty" value="1">
       *     <input type="hidden" name="purchasableId" value="{{ product.defaultVariant.id }}">
       *     <button type="submit">Add to cart</button>
       *   </form>
       */
      const _this = this;

      const values = $('#'+formId).serializeJSON();

      axios.post('/actions/commerce/cart/update-cart', {
        'purchasableId': values.purchasableId,
        'qty': values.qty,
        [this.csrfName]: this.csrfToken
      })
      .then(function (response) {
        _this.cartOpen = true;
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    cartUpdateProductQuantity(id, qty) {
      const _this = this;

      _this.cartLoading = true;
      axios.post('/actions/commerce/cart/update-cart', {
        'lineItems': {[id]: {'qty': qty}},
        [this.csrfName]: this.csrfToken
      })
      .then(function (response) {
        _this.cartUpdate();
      })
      .catch(function (error) {
        console.log(error);
        _this.cartLoading = false;
      });
    },
    cartUpdateProductNote(id, note) {
      const _this = this;

      _this.cartLoading = true;
      axios.post('/actions/commerce/cart/update-cart', {
        'lineItems': {[id]: {'note': note}},
        [this.csrfName]: this.csrfToken
      })
      .then(function (response) {
        _this.cartUpdate();
      })
      .catch(function (error) {
        console.log(error);
        _this.cartLoading = false;
      });
    },
    cartUpdateCouponCode(code) {
      const _this = this;

      _this.cartLoading = true;
      axios.post('/actions/commerce/cart/update-cart', {
        'couponCode': code,
        [this.csrfName]: this.csrfToken
      })
      .then(function (response) {
        _this.cartUpdate();
      })
      .catch(function (error) {
        console.log(error);
        _this.cartLoading = false;
      });
    },
    openVariants(id) {
      document.getElementById(id).classList.toggle('open');
    },
    cartUpdate() {
      const _this = this;
      console.log('Updating cart');

      _this.cartLoading = true;
      axios.get('/shop/cart.json',{
        headers: {
            Accept: '*/*',
            'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest',
            'X-Requested-With': 'XMLHttpRequest'
        },
    })
      .then(function (response) {
         try {
             _this.cart = JSON.parse(response.data);
         } catch (error) {
             console.log("error");
             _this.cart = response.data;
         }

      })
      .catch(function (error) {
        console.log(error);
      }).then(function() {
        _this.cartLoading = false;
      });

      // Refresh cart in case it got updated in different tab
      clearTimeout(window.cartRegularUpdate);
      window.cartRegularUpdate = setTimeout(this.cartUpdate, 120000);
    },
    cartAddress() {
      // init
      if (document.getElementsByName('billingAddressSameAsShipping').length) {
        let element = document.getElementsByName('billingAddressSameAsShipping')[0];
        this.cartAddressForm.billingAddressSameAsShipping = element.hasAttribute('checked');
      }
    }
  },
  watch: {
    cartOpen(value) {
      if (value == true) {
        this.cartUpdate();
      }
    }
  }
  });
}


    $('#gatewayId').change(function(ev){
        $('.js-gateway-fields').addClass('hidden');
        $('#fields-'+ev.currentTarget.value).removeClass('hidden');
    });

    $('.js-paymentSource-form').on('submit', function (ev) {
        $form = $(this);

        if ($form.data('processing')) {
            ev.preventDefault();

            return false;
        }

        $form.data('processing', true);
    });


// $( ".purchasableId" ).change(function(e) {
//   $(".purchasableInfo").hide();
//   $("#"+($(this).find(":selected").data('info'))).toggle();
// });
