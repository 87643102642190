<template>
<div :class="'inset-0 flex z-50 items-center justify-center ' + (show ? 'fixed' : 'hidden')" :style="{'background':black ? '#000' : '#0003', 'z-index':100}">
    <div class="popup-contain relative h-full sm:h-auto">
        <div class="h-12" v-if="!bottom && !noclose">
            <div v-on:click="() => onClose()" class="point close bg-gray-100 absolute left-0 text-center right-0 mx-auto" :class="{'bg-gold': gold}">{{ action || 'Close' }}</div>
        </div>
        <div v-on:click.stop v-body-scroll-lock="show" class="max-w-2xl mx-auto shadow-md popup-inner overflow-y-scroll overflow-x-hidden relative" v-if="show">
            <slot></slot>
        </div>
        <div class="h-12" v-if="bottom && !noclose">
            <div v-on:click="() => onClose()" class="point close absolute left-0 text-center right-0 mx-auto py-4" :class="{'bg-gold text-white': gold, 'bg-gray-100': !gold}">{{ action || 'Close' }}</div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ['show', 'action', 'black', 'bottom', 'noclose', 'gold'],
    methods: {
        onClose() {
            this.$emit('close');
        }
    }
}
</script>
