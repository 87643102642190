import { render, staticRenderFns } from "./ProductListing.vue?vue&type=template&id=63ffecc7&scoped=true&"
import script from "./ProductListing.vue?vue&type=script&lang=js&"
export * from "./ProductListing.vue?vue&type=script&lang=js&"
import style0 from "./ProductListing.vue?vue&type=style&index=0&id=63ffecc7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63ffecc7",
  null
  
)

export default component.exports