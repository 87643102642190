<template>
<div>
    <div v-on:click="show">{{ title }}</div>
    <popup-modal ref="modal" :show="showModal" v-on:close="hide" :black="black" :action="action" :bottom="bottom">
        <slot></slot>
    </popup-modal>
</div>
</template>

<script>
import PopupModal from './PopupModal'
import EventBus from '../EventBus'

export default {
    props: {
        title: String,
        action: String,
        black: Boolean,
        auto: Boolean,
        bottom: Boolean,
        noclose: Boolean,
    },
    components: {
        PopupModal,
    },
    data() {
        return {
            showModal: this.auto,
        }
    },
    methods: {
        show() {
            this.showModal = true
            EventBus.$emit('craft:noscroll', this.$refs.modal)
        },
        hide() {
            this.showModal = false
            EventBus.$emit('craft:scroll', this.$refs.modal)
        },
    }
}
</script>
